<template>
  <div class="layout-profile">
    <div>
      <Avatar :image="avatarUrl" size="xlarge" shape="circle" />
    </div>
    <br/>
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{username}}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li @click="$router.push(`/admin/users/${userId}`)">
          <button class="p-link"><i class="pi pi-fw pi-user"></i><span>Account</span></button>
        </li>
        <!--<li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>Notifications</span><span class="menuitem-badge">2</span></button></li>-->
        <li @click="logout">
          <button class="p-link"><i class="pi pi-fw pi-power-off"></i><span>Logout</span></button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import Avatar from 'primevue/avatar'
import Log from '@/services/logger'

export default {
  components: { Avatar },
  data: () => ({
    expanded: false
  }),
  computed: {
    userId: function () {
      return this.$auth.store.user?.id || '---'
    },
    username: function () {
      return `${this.$auth.store.user?.firstname} ${this.$auth.store.user?.name}`.trim() || '---'
    },
    avatarUrl: function () {
      Log(this.$auth.store.user)
      const username = this.$auth.store.user?.username
      return username ? `https://eu.ui-avatars.com/api/?name=${username}` : ''
    },
  },
  methods: {
    async logout () {
      await this.$auth.logout()
      this.$router.push('/account/login')
    },
    onClick(event){
      this.expanded = !this.expanded;
      event.preventDefault();
    }
  }
}
</script>

<style scoped>

</style>