<template>
	<div class="layout-footer">
		<span class="footer-text" style="margin-right: 5px">
      © 2014 - {{(new Date()).getFullYear()}} PXCom
    </span>
    |
		<span class="footer-text" style="margin-left: 5px">
      v{{revision.version}} | {{revision.commit.slice(0, 8)}}
    </span>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
	export default {
		name: "AppFooter",
    computed: {
      ...mapGetters('app', ['revision'])
    }
	}
</script>

<style scoped>

</style>