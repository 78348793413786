<template>
  <div>
    {{$t('common.home')}}
  </div>
</template>

<script>
import { inject, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  setup () {
    const { t, locale } = useI18n()
    const breadcrumb = inject('breadcrumb')

    watch(locale, () => (breadcrumb.setCurrent(t('common.welcome'), [])))
    onMounted(() => (breadcrumb.setCurrent(t('common.welcome'), [])))
  }
}
</script>